import * as Tooltip from "@radix-ui/react-tooltip"
import { usePageLayoutContext } from "@ui-lib/index"
import { DetailedHTMLProps, FC, Fragment, HTMLAttributes, ReactNode, useState } from "react"

interface SecondaryNavItemProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	isSelected: boolean
	isDisabled?: boolean
	label: string
	icons?: ReactNode[]
}

export const SecondaryNavItem: FC<SecondaryNavItemProps> = (props: SecondaryNavItemProps) => {
	const { label, isSelected, isDisabled, icons, ...rest } = props
	const [openTooltip, setOpenTooltip] = useState<boolean>(false)
	let hovering = false
	const [clickOutsidePending, setClickOutsidePending] = useState(false)
	const { translationFunc } = usePageLayoutContext()

	return (
		<div
			onMouseDown={(e) => {
				if (isDisabled === true) {
					return
				}
				const elem = document.activeElement as HTMLElement | null
				elem?.blur?.()
				e.preventDefault()
				props.onClick
			}}
			className={`focus-style flex h-[32px] w-full flex-row items-center justify-between overflow-x-clip whitespace-nowrap rounded-sm px-2 text-left text-button font-semibold ring-inset 
            ${
				isSelected === true
					? `cursor-default bg-white bg-opacity-10 text-dipai-primary-501 text-opacity-100`
					: isDisabled === true
					? `cursor-default text-white text-opacity-30`
					: `cursor-pointer text-white text-opacity-60 hover:bg-black hover:bg-opacity-20 hover:text-white group-hover:text-opacity-100`
			}`}
			{...rest}
		>
			{isDisabled === true ? (
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={50} open={openTooltip}>
						<Tooltip.Trigger
							// asChild
							onClick={(e) => {
								e.stopPropagation()
								if (!clickOutsidePending) {
									setOpenTooltip(!openTooltip)
								}
								setClickOutsidePending(false)
							}}
							onMouseEnter={() => {
								hovering = true
								setTimeout(() => {
									if (hovering) {
										setOpenTooltip(true)
									}
								}, 200)
							}}
							onMouseLeave={() => {
								hovering = false
								setTimeout(() => {
									setOpenTooltip(false)
								}, 200)
							}}
						>
							<p className={`cursor-help`}>{label} </p>
						</Tooltip.Trigger>
						<Tooltip.Portal>
							<Tooltip.Content
								className={`z-[10001] h-auto w-auto rounded-sm bg-dipai-secondary-800 bg-opacity-[0.85] px-2 py-1 text-white text-opacity-100`}
								sideOffset={5}
								side={`right`}
								onPointerDownOutside={(e) => {
									setOpenTooltip(false)
									setClickOutsidePending(true)
								}}
								onEscapeKeyDown={() => setOpenTooltip(false)}
							>
								<p>
									{translationFunc !== undefined
										? translationFunc(
												`THIS MODULE IS NOT AVAILABLE FOR THIS VESSEL`
										  )
										: `This module is not available for this vessel`}
								</p>
								<Tooltip.Arrow
									className={`fill-dipai-secondary-800 opacity-[0.85]`}
								/>
							</Tooltip.Content>
						</Tooltip.Portal>
					</Tooltip.Root>
				</Tooltip.Provider>
			) : (
				label
			)}

			{icons !== undefined ? (
				<div className={`flex flex-row justify-end gap-2`}>
					{icons.map((icon, index) => (
						<Fragment key={`icon_${index}`}>{icon}</Fragment>
					))}
				</div>
			) : null}
		</div>
	)
}
