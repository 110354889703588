import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PlantInfo } from "api"

export interface PlantInfoFE extends PlantInfo {
	latestOperationMode2?: { plantId: number; opModeName: string }
}

type State = {
	loaded: boolean
	vessels: PlantInfoFE[]
}

const initialState: State = {
	loaded: false,
	vessels: [],
}

const listOfVesselsSlice = createSlice({
	name: "listOfVessels",
	initialState,
	reducers: {
		setListOfVessels(state, action: PayloadAction<State>) {
			return { ...state, ...action.payload }
		},
	},
})

export const getListOfVessels = (state: RootState) => state.listOfVessels

export const { setListOfVessels } = listOfVesselsSlice.actions

export default listOfVesselsSlice.reducer
