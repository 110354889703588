import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	BwVesselTrackData,
	BwVisitedLocalityResponse,
	OpModeEventData,
	OpModeLiveData,
	VesselInfoOperation,
} from "api"

type VesselTimelineData = {
	plantId: number
	/**
	 * @deprecated
	 */
	name?: string
	track?: BwVesselTrackData
	visitedLocations?: BwVisitedLocalityResponse
	operationModeEvents?: OpModeEventData
}

export type VesselConnectionStatus = "online" | "offline"

export type VesselInfo = VesselInfoOperation &
	VesselTimelineData & { latestOperationMode?: OpModeLiveData } & {
		latestOperationMode2?: { plantId: number; opModeName: string }
	} & {
		name: string
	} & { condition?: string | VesselConnectionStatus }

type SelectedVessel = {
	vesselInfo: VesselInfo
	status: RequestStatus
	error: string
}

const initialState: SelectedVessel = {
	vesselInfo: {
		name: "",
		position: undefined,
		latestOperationMode: undefined,
		latestOperationMode2: undefined,
		vessel: undefined,
		track: undefined,
		visitedLocations: undefined,
		operationModeEvents: undefined,
		plantId: -1,
	},
	status: RequestStatus.idle,
	error: "",
}

const selectedVesselSlice = createSlice({
	name: "selectedVessel",
	initialState,
	reducers: {
		setSelectedVessel(
			state,
			action: PayloadAction<
				VesselInfoOperation & {
					latestOperationMode?: OpModeLiveData
				} & {
					latestOperationMode2?: { plantId: number; opModeName: string }
				}
			>
		) {
			return { ...state, vesselInfo: { ...state.vesselInfo, ...action.payload } }
		},
		clearSelectedVessel(state) {
			return { ...state, ...initialState }
		},
	},
})

export const getSelectedVessel = (state: RootState) => state.selectedVessel.vesselInfo

export const { setSelectedVessel, clearSelectedVessel } = selectedVesselSlice.actions

export default selectedVesselSlice.reducer
